.about {
  height: 100vh;
  width: 100vw;
  background-color: #1a2130;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f5eded;
}

.about .svg {
  width: 50vw;
  background-color: #f5eded04;
  filter: brightness(60%);
  animation: click 3s infinite;
}

.about .svg svg {
  height: 100vh;
}

.about .text_about {
  text-align: start;
  padding: 5%;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(6.5px);
  -webkit-backdrop-filter: blur(6.5px);
  border-radius: 10px;
}
.about .text_about h3 {
  text-align: center;
}

@keyframes click {
  0% {
    filter: brightness(60%);
  }
  79% {
    filter: brightness(60%);
  }
  80% {
    filter: brightness(90%);
  }
  85% {
    filter: brightness(60%);
  }
  90% {
    filter: brightness(100%);
  }
  97% {
    filter: brightness(40%);
  }
  100% {
    filter: brightness(60%);
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .about {
    flex-direction: column;
  }
  .about .svg {
    width: 100vw;
  }
  .about .svg img {
    margin-top: 15vh;
    height: 50vh;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .about {
    flex-direction: column;
  }
  .about .svg {
    width: 100vw;
  }
  .about .svg img {
    height: 50vh;
  }
}

/* Medium devices (large tablets, 868px and up) */
@media (max-width: 868px) {
  .about {
    flex-direction: column;
  }
  .about .svg {
    width: 100vw;
  }
}

/*  Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (max-width: 1400px) {
}
