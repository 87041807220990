.services {
  background-color: #1a2130;
  min-height: 100vh;
}

.services .ReactGridGallery_tile-viewport {
  background-color: #1a2130;
  display: flex;
  justify-content: center;
  align-items: center;
}

.services .preview img {
  width: 100%;
  height: 100%;
}

.services .img_preview {
  filter: grayscale(100%);
  filter: brightness(55%);
  transition: ease-in 1s;
}

.services .img_preview:hover,
.services .img_preview:active {
  filter: grayscale(0%);
  filter: brightness(100%);
}

@media (hover: none) {
  .services .img_preview {
    filter: grayscale(0%);
    filter: brightness(100%);
  }
}

.full_window {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  user-select: none;
  z-index: 9999 !important;
}

.full_window .img_preview {
  filter: grayscale(0%);
  filter: brightness(100%);
}

.full_window .btn-transparent {
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  color: #ffffff;
  position: absolute;
  z-index: 99999 !important;
}

.close {
  top: 20px;
  right: 30px;
}

.full_window .btn-transparent:hover {
  background-color: #1a2130;
  color: #ffffff;
}
.full_window .skip {
  position: absolute;
  top: 90vh;
  left: 50%;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-50%, -50%);
}

.full_window .skip .larrow {
  right: 30px;
}
.full_window .skip .rarrow {
  left: 30px;
}
