.App {
  text-align: center;
}

.navigation_bar {
  background: rgba(71, 140, 207, 0.12);
  border-radius: 5px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  box-shadow: 3px 4px 9px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5.9px);
  -webkit-backdrop-filter: blur(5.9px);
  border: 0px solid rgba(71, 140, 207, 0.49);
}
.navigation_bar a {
  color: #f5eded !important;
  font-size: larger !important;
  font-weight: 800;
}

.navigation_bar a:hover {
  color: #1a2130 !important;
}
.navigation_bar li {
  font-size: medium !important;
}

.header_active {
  text-decoration: underline !important;
  text-decoration-color: rgb(255, 191, 0) !important;
  text-decoration-thickness: 3px !important;
}
