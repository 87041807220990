.contact {
  height: 100vh;
  background-color: #1a2130;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contact img {
  height: 40vh;
  width: 80vw;
}
