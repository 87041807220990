#textblock {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1a213000;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: row;
}

#textblock-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(#1a213000, #1a213075, #1a2130a4, #1a2130ea);
  padding: 5% 2%;
}

#textblock-title {
  color: #f5eded;
  font-size: 35px;
  font-weight: 600;
  font-family: "Helvetica Neue";
}

#textblock-content {
  color: #f5eded;
  font-size: 20px;
}

#textblock-footer {
  color: #f5eded;
  font-size: 15px;
  font-weight: 400;
  position: fixed;
  width: 100%;
  bottom: 0px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

#textblock-devsense {
  text-decoration: none;
  color: #f5eded;
  font-size: 15px;
  font-weight: 600;
}

.animation,
.animation_layer {
  height: 100vh;
}

.animation {
  display: block;
  position: relative;
  z-index: 10;
}

.animation_layer {
  background-position: bottom center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: absolute;
}

.animation_layer_1 {
  background-position: bottom center;
  background-size: auto 1038px;
  background-repeat: no-repeat;
  width: 50%;
  position: absolute;
}
.animation_layer.parallax {
  position: fixed;
}

.svg_title {
  background-color: #210002;
  position: absolute;
  height: 50vh;
  width: 50vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(71, 140, 207, 0.32);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.9px);
  -webkit-backdrop-filter: blur(5.9px);
  border: 1px solid rgba(71, 140, 207, 0.49);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.svg_title svg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15rem;
  width: 30rem;
  font-size: 8rem;
  padding: 0;
  font-weight: bolder;
  fill: none;
  stroke: #ffac1c;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  font-family: sans-serif;
  animation: svg_text_stroke 13s infinite;
  animation-timing-function: linear;
  -webkit-filter: drop-shadow(2px 3px 1px #ffac1c70);
  filter: drop-shadow(2px 3px 1px #ffac1c70);
  transition: 3s ease-in-out;
}

.slide_image {
  height: 100vh;
  min-width: 100%;
}

.description {
  width: 100vw;
  min-height: 200vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1a2130;
  color: #f5eded;
  padding: 10px 0;
}

.description .card {
  background-color: transparent;
  margin: 30px;
  width: 80%;
  display: flex;
  flex-direction: row;
  background-color: #f5eded24;
  border-radius: 5px;
  padding: 1%;
  cursor: pointer !important;
}

.description .card .text {
  height: 100%;
  width: 50%;
  background-image: radial-gradient(transparent 5%, #1a2130ac);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-shadow: 2px 4px 4px #1a2130;
  padding: 10% 1%;
}

.description .card img {
  height: 40vh;
  width: 40vw;
}

.description .float {
  z-index: 99;
  position: relative;
}

.description .float button {
  background-color: #1a2130 !important;
  border: 1px solid #f5eded !important;
}

.full_window {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  user-select: none;
  z-index: 9999 !important;
}

.full_window .img_preview {
  filter: grayscale(0%);
  filter: brightness(100%);
}
.full_window .img_preview img {
  width: 100vw;
}

.full_window .btn-transparent {
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  color: #ffffff;
  position: absolute;
  z-index: 99998 !important;
}

.close {
  top: 20px;
  right: 30px;
}

.full_window .btn-transparent:hover {
  background-color: #1a2130;
  color: #ffffff;
}

.full_window .img_preview .text {
  position: absolute;
  height: auto;
  width: 100vw;
  top: 90vh;
  padding: 10px 30px;
  background-color: #1a2130bd;
  z-index: 99999 !important;
  font-size: larger;
  font-weight: 700;
}

@keyframes svg_text_stroke {
  0% {
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    fill: none;
  }
  100% {
    stroke-dasharray: 750;
    stroke-dashoffset: 100;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .svg_title {
    height: 50vh;
    width: 95vw;
  }
  .svg_title .image {
    width: 65% !important;
  }
  .svg_title svg {
    font-size: 3.5rem;
  }
  .description .card {
    flex-direction: column;
  }
  .description .card .text {
    width: 100%;
  }
  .description {
    min-height: 150vh;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .svg_title {
    height: 50vh;
    width: 75vw;
  }
  .svg_title .image {
    width: 55% !important;
  }
  .svg_title svg {
    font-size: 5rem;
  }
  .description .card {
    flex-direction: column;
  }
  .description .card img {
    height: 20vh;
    width: 80vw;
  }
  .description .card .text {
    width: 100%;
  }

  .description {
    min-height: 150vh;
  }
}

/* Medium devices (large tablets, 868px and up) */
@media (max-width: 868px) {
  .svg_title {
    height: 50vh;
    width: 85vw;
  }
  .svg_title .image {
    width: 45% !important;
  }
  .svg_title svg {
    font-size: 6rem;
  }
  .description .card {
    flex-direction: column;
  }
  .description .card img {
    height: 20vh;
    width: 60vw;
  }
  .description .card .text {
    width: 100%;
  }
  .description {
    min-height: 150vh;
  }
}

/*  Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
}

/* X-Large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (max-width: 1400px) {
}
